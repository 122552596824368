import dayjs from 'dayjs';
import _ from 'lodash';
import { FunctionComponent, SyntheticEvent, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetDistributionGame } from '~components/hooks';
import { Button, Price } from '~ui/index';

import { gql, useMutation } from '@apollo/client';

import { AuthBlock } from '../AuthBlock';
import Currency from '../Currency';
import { DistributionGamesHistory } from '../distributionModal';
import {
  REFETCH_STEAM,
  REFETCH_TELEGRAM,
  REFETCH_VK,
  SIGN_DISTRIBUTION,
  useConfig,
  useTimer,
} from '../hooks';
import { GetName, SteamItemImage } from '../item';
import { Loader } from '../Loader';
import { useProfile } from '../profile/hooks';
import { Timer } from '../Timer';
import { useModal } from './hooks';
import Modal from './Modal';

interface Props {
  id: number;
  onClose: () => void;
}

const DistributionModalWrap: FunctionComponent<Props> = ({ id, onClose }) => {
  const [cookies, setCookie] = useCookies([
    'setRefetchFacebook',
    'setRefetchTwitter',
  ]);
  const [isRefetchSteam, setRefetchSteam] = useState(false);
  const [isRefetchTwitter, setRefetchTwitter] = useState(
    !!cookies?.setRefetchTwitter,
  );
  const [isRefetchFacebook, setRefetchFacebook] = useState(
    !!cookies?.setRefetchFacebook,
  );
  const [isRefetchVk, setRefetchVk] = useState(false);
  const [isRefetchTelegram, setRefetchTelegram] = useState(false);
  const getProfile = useProfile();
  const getConfig = useConfig();
  const { t } = useTranslation();
  const { loading, data, error } = useGetDistributionGame(id);
  const [signDistribution, { loading: loadingMutation }] = useMutation(
    SIGN_DISTRIBUTION,
    {
      variables: {
        input: {
          distributionGameId: id,
        },
      },
    },
  );

  const [refetchSteam, { loading: loadingRefetchSteam }] =
    useMutation(REFETCH_STEAM);
  const [refetchVk, { loading: loadingRefetchVk }] = useMutation(REFETCH_VK);
  const [refetchTelegram, { loading: loadingRefetchTelegram }] =
    useMutation(REFETCH_TELEGRAM);
  const [refetchTwitter] = useMutation(gql`
    mutation refetchTwitter {
      refetchTwitter
    }
  `);
  const [refetchFacebook] = useMutation(gql`
    mutation refetchFacebook {
      refetchFacebook
    }
  `);

  const onClickRefetchSteam = () => {
    setRefetchSteam(true);
  };

  const onClickRefetchVk = () => {
    setRefetchVk(true);
  };

  const onClickRefetchTelegram = () => {
    setRefetchTelegram(true);
  };

  const onRefetchSteam = async () => {
    try {
      if (loadingRefetchSteam) {
        return;
      }

      await refetchSteam();
    } catch {
      //
    }

    setRefetchSteam(false);
  };

  const onRefetchVk = async () => {
    try {
      if (loadingRefetchVk) {
        return;
      }

      await refetchVk();
    } catch {
      //
    }

    setRefetchVk(false);
  };

  const onRefetchTelegram = async () => {
    try {
      if (loadingRefetchTelegram) {
        return;
      }

      await refetchTelegram();
    } catch {
      //
    }

    setRefetchTelegram(false);
  };

  const onSignDistribution = async (event: SyntheticEvent) => {
    try {
      event.preventDefault();
      await signDistribution();
    } catch (error) {
      toast.error(
        <>
          <div className="notify-title">{t('Error')}!</div>
          <div
            className="notify-text"
            dangerouslySetInnerHTML={{ __html: t(error.message) }}
          />
        </>,
      );
    }
  };

  const getDistributionGame = data?.getDistributionGame;
  const getDistribution = getDistributionGame?.getDistribution;
  const timer = useTimer(getDistributionGame?.endDate);

  const isDone = useMemo(() => {
    if (
      getDistribution?.conditions?.user?.depositSum > 0 &&
      !getProfile?.getDistributionsPaymentConditions[getDistribution?.id]
    ) {
      return false;
    } else if (
      getDistribution?.conditions?.user?.isDomainNickName &&
      !_.includes(
        getProfile?.getSteam?.userName,
        getConfig?.hostname.toUpperCase(),
      )
    ) {
      return false;
    }

    return true;
  }, [
    getConfig?.hostname,
    getDistribution?.conditions?.user?.depositSum,
    getDistribution?.conditions?.user?.isDomainNickName,
    getDistribution?.id,
    getProfile?.getDistributionsPaymentConditions,
    getProfile?.getSteam?.userName,
  ]);

  const onTelegram = () => {
    if (getProfile?.getTelegram?.props?.joinedToGroup) {
      return false;
    }
    if (isRefetchTelegram) {
      return onRefetchTelegram();
    }

    window.open(
      !getProfile?.getTelegram
        ? '/auth/telegram?link=true'
        : getConfig?.seo.telegram,
      '_blank',
    );

    if (getProfile?.getTelegram) {
      setRefetchTelegram(true);
    }
  };

  const onTwitter = async (event: SyntheticEvent) => {
    if (getProfile?.joinedToTwitter || isRefetchTwitter) {
      event.preventDefault();
      return false;
    }

    setRefetchTwitter(true);

    await refetchTwitter();
    const expires = dayjs().add(15, 'seconds');

    setCookie('setRefetchTwitter', expires.format(), {
      expires: expires.toDate(),
    });

    setTimeout(() => setRefetchTwitter(false), 15000);
  };

  const onFacebook = async () => {
    if (getProfile?.joinedToFacebook || isRefetchFacebook) {
      return false;
    }

    setRefetchFacebook(true);

    await refetchFacebook();

    const expires = dayjs().add(15, 'seconds');

    setCookie('setRefetchFacebook', expires.format(), {
      expires: expires.toDate(),
    });

    setTimeout(() => setRefetchFacebook(false), 15000);
  };

  const onVk = () => {
    if (getProfile?.getVk?.props?.joinedToGroup) {
      return false;
    }
    if (isRefetchVk) {
      return onRefetchVk();
    }

    window.open(
      !getProfile?.getVk ? '/auth/vk?link=true' : 'https://vk.com/cscase',
      '_blank',
    );

    if (getProfile?.getVk) {
      setRefetchVk(true);
    }
  };

  const onSteam = () => {
    if (isRefetchSteam) {
      return onRefetchSteam();
    }

    window.open(
      !getProfile?.getSteam
        ? '/auth/steam?link=true'
        : 'https://steamcommunity.com/id/me/edit/info',
      '_blank',
    );

    if (getProfile?.getSteam) {
      setRefetchSteam(true);
    }
  };

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <>DistributionModal | Error! {error.message}</>;
  }

  const getItem = getDistributionGame?.getItem;

  return (
    <div className="box-modal-wrap">
      <div className="box-modal modal-blue modal-blue-demo-game popup-demo-free">
        <div className="box-modal_close arcticmodal-close" onClick={onClose}>
          <div className="icon material-icons">close</div>
        </div>
        <div className="popup-demo-free-user">
          <div className="popup-demo-free-title">Public Hash</div>
          <div className="popup-demo-free-hash">
            {getDistributionGame?.publicHash}
          </div>
          <DistributionGamesHistory
            distributionId={getDistributionGame?.distributionId}
          />
        </div>
        <div className="popup-demo-free-prize">
          <div className="popup-demo-free-title">
            {t(getDistributionGame.getType)} #{getDistributionGame?.id}
          </div>
          <div className="popup-demo-free-prize-title">{t('Distribution')}</div>
          {timer && (
            <div className="page-timer">
              <div className="page-timer-wrap">
                <div className="page-timer-day">
                  <span>
                    {timer?.days} {t('d')}.
                  </span>
                </div>
                <div className="page-timer-hours">{timer?.hours}</div>
                <div className="page-timer-separator">:</div>
                <div className="page-timer-minutes">{timer?.minutes}</div>
                <div className="page-timer-separator">:</div>
                <div className="page-timer-seconds">{timer?.seconds}</div>
              </div>
            </div>
          )}
          <div className="popup-demo-free-prize-text">
            {t('DistributionModal popup-demo-free-prize-text')}
          </div>
          <div
            className="in-case-item active status-4"
            data-rar={getItem?.color}
          >
            <div className="in-case-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getDistributionGame?.price} />
                </>
              ) : (
                <>
                  <Price sum={getDistributionGame?.price} />
                  <Currency />
                </>
              )}
            </div>
            <div className="in-case-item-wrap">
              <div className="img">
                <div className="in-case-item-bg" />
                <SteamItemImage url={getItem?.getImage} size="350x350" />
              </div>
              <div className="in-case-info">
                <div className="in-case-item-name">
                  <GetName getItem={getItem} type={0} />
                </div>
                <div className="in-case-item-desc">
                  <GetName getItem={getItem} type={1} />
                </div>
              </div>
            </div>
            <div style={{ marginTop: '25px' }}>
              <span style={{ color: '#ffc600' }}>
                {t('deposits_for_the_day')}:{' '}
              </span>
              {getProfile?.deposit24Hour / 100}
              <span>
                <Currency />
              </span>
            </div>
          </div>
          <div className="popup-demo-free-steps">
            {!getProfile && (
              <div className="popup-demo-free-steps-item">
                <div className="popup-demo-free-steps-item-text">
                  <div className="popup-demo-free-steps-item-text-pink">
                    {t(
                      'DistributionModal popup-demo-free-steps-item-text-pink',
                    )}
                  </div>
                </div>
              </div>
            )}
            {getDistribution?.conditions?.user?.depositSum > 0 && (
              <Link
                to="/pay"
                className={`popup-demo-free-steps-item ${
                  (getDistributionGame.isJoin || !getProfile) && 'disabled'
                }`}
              >
                <div className="popup-demo-free-steps-item-text">
                  <div className="popup-demo-free-steps-item-text-pink">
                    {t(
                      'DistributionModal popup-demo-free-steps-item-text popup-demo-free-steps-item-text-pink depositSum',
                    )}
                    <span>
                      {getDistribution?.conditions?.user?.depositSum}{' '}
                      <Currency />
                    </span>
                  </div>
                </div>
                {getProfile && (
                  <>
                    {!getProfile?.getDistributionsPaymentConditions[
                      getDistribution?.id
                    ] ? (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">done</div>
                      </span>
                    )}
                  </>
                )}
              </Link>
            )}

            {getDistribution?.conditions?.user?.isJoinTwitter && (
              <a
                href={getConfig?.seo.twitter}
                onClick={onTwitter}
                className={`popup-demo-free-steps-item ${
                  (getDistributionGame.isJoin || !getProfile) && 'disabled'
                }`}
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                <div className="popup-demo-free-steps-item-text">
                  <div className="popup-demo-free-steps-item-text-pink">
                    {isRefetchTwitter ? (
                      <>
                        {t('checkOutOur')} Twitter:{' '}
                        <Timer endDate={cookies?.setRefetchTwitter} />
                      </>
                    ) : (
                      <>{t('Subscribe to news')} Twitter</>
                    )}
                  </div>
                </div>
                {getProfile && (
                  <>
                    {isRefetchTwitter ? (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : !getProfile?.joinedToTwitter ? (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">done</div>
                      </span>
                    )}
                  </>
                )}
              </a>
            )}

            {getDistribution?.conditions?.user?.isJoinFacebook && (
              <a
                href={getConfig?.seo.fb}
                onClick={onFacebook}
                className={`popup-demo-free-steps-item ${
                  (getDistributionGame.isJoin || !getProfile) && 'disabled'
                }`}
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                <div className="popup-demo-free-steps-item-text">
                  <div className="popup-demo-free-steps-item-text-pink">
                    {!getProfile?.joinedToFacebook && isRefetchFacebook ? (
                      <>
                        {t('checkOutOur')} Facebook:{' '}
                        <Timer endDate={cookies?.setRefetchFacebook} />
                      </>
                    ) : (
                      <>{t('Subscribe to news')} Facebook</>
                    )}
                  </div>
                </div>
                {getProfile && (
                  <>
                    {isRefetchFacebook && !getProfile?.joinedToFacebook ? (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : !getProfile?.joinedToFacebook ? (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">done</div>
                      </span>
                    )}
                  </>
                )}
              </a>
            )}

            {getDistribution?.conditions?.user?.isJoinTelegramGroup && (
              <div
                onClick={onTelegram}
                className={`popup-demo-free-steps-item ${
                  (getDistributionGame.isJoin || !getProfile) && 'disabled'
                }`}
              >
                <div className="popup-demo-free-steps-item-text">
                  {!getProfile?.getTelegram ? (
                    <div className="popup-demo-free-steps-item-text-pink">
                      {t('Link your telegram account')}
                    </div>
                  ) : (
                    <div className="popup-demo-free-steps-item-text-pink">
                      {t(
                        'DistributionModal popup-demo-free-steps-item-text popup-demo-free-steps-item-text-pink isJoinTelegramGroup',
                      )}
                    </div>
                  )}
                </div>
                {getProfile && (
                  <>
                    {isRefetchTelegram ? (
                      <span
                        className="popup-demo-free-steps-item-link replay"
                        onClick={onRefetchTelegram}
                      >
                        <div className="icon material-icons">replay</div>
                      </span>
                    ) : !getProfile?.getTelegram ? (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : !getProfile?.getTelegram?.props?.joinedToGroup ? (
                      <span
                        className="popup-demo-free-steps-item-link"
                        onClick={onClickRefetchTelegram}
                      >
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">done</div>
                      </span>
                    )}
                  </>
                )}
              </div>
            )}

            {getDistribution?.conditions?.user?.isJoinVkGroup && (
              <div
                onClick={onVk}
                className={`popup-demo-free-steps-item ${
                  (getDistributionGame.isJoin || !getProfile) && 'disabled'
                }`}
              >
                <div className="popup-demo-free-steps-item-text">
                  {!getProfile?.getVk ? (
                    <div className="popup-demo-free-steps-item-text-pink">
                      {t('Link your vk account')}
                    </div>
                  ) : (
                    <div className="popup-demo-free-steps-item-text-pink">
                      {t(
                        'DistributionModal popup-demo-free-steps-item-text popup-demo-free-steps-item-text-pink isJoinVkGroup',
                      )}
                    </div>
                  )}
                </div>
                {getProfile && (
                  <>
                    {isRefetchVk ? (
                      <span
                        className="popup-demo-free-steps-item-link replay"
                        onClick={onRefetchVk}
                      >
                        <div className="icon material-icons">replay</div>
                      </span>
                    ) : !getProfile?.getVk ? (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : !getProfile?.getVk?.props?.joinedToGroup ? (
                      <span
                        className="popup-demo-free-steps-item-link"
                        onClick={onClickRefetchVk}
                      >
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">done</div>
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
            {getDistribution?.conditions?.user?.isDomainNickName && (
              <div
                onClick={onSteam}
                className={`popup-demo-free-steps-item ${
                  (getDistributionGame.isJoin || !getProfile) && 'disabled'
                }`}
              >
                <div className="popup-demo-free-steps-item-text">
                  {!getProfile?.getSteam ? (
                    <div className="popup-demo-free-steps-item-text-pink">
                      {t('Link your steam account')}
                    </div>
                  ) : (
                    <div className="popup-demo-free-steps-item-text-pink">
                      {t(
                        'DistributionModal popup-demo-free-steps-item-text popup-demo-free-steps-item-text-pink steam nickname',
                      )}
                      <span>{_.toUpper(getConfig?.hostname)}</span>
                    </div>
                  )}
                </div>
                {getProfile && (
                  <>
                    {isRefetchSteam ? (
                      <span
                        className="popup-demo-free-steps-item-link replay"
                        onClick={onRefetchSteam}
                      >
                        <div className="icon material-icons">replay</div>
                      </span>
                    ) : !getProfile?.getSteam ? (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : !_.includes(
                        _.toUpper(getProfile?.getSteam?.userName),
                        _.toUpper(getConfig?.hostname),
                      ) ? (
                      <span
                        className="popup-demo-free-steps-item-link"
                        onClick={onClickRefetchSteam}
                      >
                        <div className="icon material-icons">call_made</div>
                      </span>
                    ) : (
                      <span className="popup-demo-free-steps-item-link">
                        <div className="icon material-icons">done</div>
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
            {getDistribution?.conditions?.user?.totalPlayTime > 0 && (
              <div className="popup-demo-free-steps-item">
                <div className="popup-demo-free-steps-item-text">
                  <div className="popup-demo-free-steps-item-text-pink">
                    {t(`More than {totalPlayTime} hours in CS:GO`, {
                      totalPlayTime:
                        getDistribution?.conditions?.user?.totalPlayTime,
                    })}
                  </div>
                </div>
              </div>
            )}
            {false && (
              <div className="popup-demo-free-steps-item">
                <div className="popup-demo-free-steps-item-text">
                  <div className="popup-demo-free-steps-item-text-pink">
                    <i className="violet">({t('Optional')})</i>{' '}
                    {t(
                      `Subscribe to newsletter. We'll notify you of the results`,
                    )}
                  </div>
                </div>
                <a
                  className="popup-demo-free-steps-item-link"
                  href="https://vk.com/app5898182_-152839667#s=1653709&force=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="icon material-icons">call_made</div>
                </a>
              </div>
            )}
          </div>
          {getProfile ? (
            !getDistributionGame.isJoin ? (
              <Button
                className={`btn-yellow ${!isDone && 'disabled'}`}
                onClick={onSignDistribution}
                loading={loadingMutation}
              >
                {t('Take part')}
              </Button>
            ) : (
              <Button className="btn-yellow disabled disabled-done">
                <div className="distribution-item-controls-counter">
                  <div className="icon material-icons">group</div>
                  <span>{getDistributionGame.usersCount}</span>
                </div>
                <div className="icon material-icons">call_made</div>
                <span className="text">
                  {t('distribution-item distribution-item-controls-part span')}
                </span>
              </Button>
            )
          ) : (
            <>
              <div className="open-cases-btns">
                <AuthBlock />
              </div>
              <Button className={`btn-yellow disabled`}>
                {t('Take part')}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const DistributionModal: FunctionComponent = () => {
  const modalName = 'DistributionModal';

  const {
    onClose,
    isOpen,
    props: { id },
  } = useModal<{ id: number }>(modalName);

  return (
    <Modal show={isOpen} onClose={onClose} modalName={modalName} width={824}>
      <DistributionModalWrap onClose={onClose} id={id} />
    </Modal>
  );
};
