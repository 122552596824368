import _ from 'lodash';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DistributionGame as DistributionGameTypes } from '~types';
import { useGetDistributionGames, useConfig } from '~components/hooks';
import { Price } from '~shared/frontend/ui';
import Currency from '../Currency';
import { Loader } from '../Loader';
import { useOpenModal } from '../modals/hooks';

interface Props {
  distributionId: number;
}

interface DistributionGameProps {
  getDistributionGame: DistributionGameTypes;
}

export const DistributionGame: FunctionComponent<DistributionGameProps> = ({
  getDistributionGame,
}: DistributionGameProps) => {
  const { t } = useTranslation();
  const { onShow, onClose } = useOpenModal();
  const getConfig = useConfig();

  const showModal = () => {
    onShow('DistributionWinModal', {
      id: getDistributionGame.id,
    });

    onClose('DistributionModal');
  };

  return (
    <div
      className="popup-demo-free-user-items-row"
      key={getDistributionGame.id}
      onClick={showModal}
    >
      <div className="popup-demo-free-user-items-row-num">
        #{getDistributionGame?.id}
      </div>
      <div className="popup-demo-free-user-items-row-prize">
        <div
          className="top-drop-item status-6"
          data-rar={getDistributionGame?.getItem?.color}
        >
          <div className="honey"></div>
          <div className="in-case-cost">
            {getConfig.isEU ? (
              <>
                <Currency />
                <Price sum={getDistributionGame?.price} />
              </>
            ) : (
              <>
                <Price sum={getDistributionGame?.price} />
                <Currency />
              </>
            )}
          </div>
          <div
            className="drop-img"
            style={{
              backgroundImage: `url(${getDistributionGame?.getItem?.getImage})`,
            }}
          />
          <div className="top-drop-item-hover">
            <div className="top-drop-item-name">
              {getDistributionGame?.getItem?.getName}
            </div>
          </div>
        </div>
      </div>
      <div className="popup-demo-free-user-items-row-user">
        <Link
          className="popup-demo-free-user-items-row-user-ava"
          to={`/user/${getDistributionGame?.getWinner?.id}`}
        >
          <img src={getDistributionGame?.getWinner?.avatar} />
          <div className="tooltipe">{t('To the profile')}</div>
        </Link>
        <div className="popup-demo-free-user-items-row-user-name">
          {getDistributionGame?.getWinner?.userName}
        </div>
      </div>
    </div>
  );
};

export const DistributionGamesHistory: FunctionComponent<Props> = ({
  distributionId,
}) => {
  const { t } = useTranslation();
  const { data, loading, error } = useGetDistributionGames({
    distributionId,
    limit: 10,
    offset: 0,
    filterOrder: { sortOrder: 'desc', sortField: 'id' },
  });

  const getDistributionGames = data?.getDistributionGames;

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <>DistributionGamesHistory | Error! {error.message}</>;
  }

  return (
    <>
      <div className="popup-demo-free-title">
        {t('DistributionModal popup-demo-free-title history games')}
      </div>
      <div className="popup-demo-free-user-items">
        {_.map(getDistributionGames, getDistributionGame => (
          <DistributionGame
            getDistributionGame={getDistributionGame}
            key={getDistributionGame.id}
          />
        ))}
      </div>
    </>
  );
};
